import React, { useEffect } from "react";
import { Layout } from "../../components/Layout/Layout";
import "./Politicas.css";
import { goTop } from "../../helpers/goTop";

const Politicas = () => {
  useEffect(() => {
    goTop();
  }, []);

  return (
    <div>
      <div className="contenedor-showroom__header">
        <p className="contenedor-showroom__header__text">
          <strong>Políticas de privacidad</strong>
        </p>
      </div>
      <Layout>
        <h2 className="contenedor-empresa__quienes-somos__enunciado__titulo politicas">
          Viviendas la Esperanza
        </h2>
        <p className="contenedor-empresa__politicas__enunciado__titulo">
          Nos tomamos muy en serio la privacidad de nuestros clientes y
          visitantes de nuestro sitio web. Esta política de privacidad describe
          cómo recopilamos, utilizamos y protegemos la información que nos
          proporcionas al visitar nuestro sitio web o utilizar nuestros
          servicios.
        </p>
        <h2 className="contenedor-empresa__quienes-somos__enunciado__titulo politicas">
          Recopilación de información
        </h2>
        <p className="contenedor-empresa__politicas__enunciado__titulo">
          Cuando visitas nuestro sitio web, podemos recopilar cierta información
          sobre tu dispositivo y tu navegación en nuestro sitio, como tu
          dirección IP, tu navegador web, tus páginas visitadas y el tiempo que
          pasas en nuestro sitio. También podemos utilizar cookies para
          recopilar información sobre tus preferencias y para personalizar tu
          experiencia en nuestro sitio web. Si decides proporcionarnos
          información personal, como tu nombre, correo electrónico o número de
          teléfono, solo la utilizaremos para los fines específicos para los que
          nos la proporcionaste.
        </p>
        <h2 className="contenedor-empresa__quienes-somos__enunciado__titulo politicas">
          Uso de la información
        </h2>
        <p className="contenedor-empresa__politicas__enunciado__titulo">
          Utilizamos la información que recopilamos para brindar nuestros
          servicios y mejorar la experiencia del usuario en nuestro sitio web.
          También podemos utilizar tu información para contactarte por correo
          electrónico, teléfono o WhatsApp para proporcionarte información sobre
          nuestros productos y servicios, siempre y cuando hayas dado tu
          consentimiento explícito para recibir dichos mensajes.
        </p>
        <h2 className="contenedor-empresa__quienes-somos__enunciado__titulo politicas">
          Seguridad de la información
        </h2>
        <p className="contenedor-empresa__politicas__enunciado__titulo">
          Tomamos medidas razonables para proteger la información que
          recopilamos y almacenamos, incluyendo medidas de seguridad físicas,
          electrónicas y administrativas. Sin embargo, ninguna medida de
          seguridad es infalible y no podemos garantizar la seguridad absoluta
          de la información que nos proporcionas.
        </p>
        <h2 className="contenedor-empresa__quienes-somos__enunciado__titulo politicas">
          Consentimiento para el envío de mensajes por WhatsApp
        </h2>
        <p className="contenedor-empresa__politicas__enunciado__titulo">
          Si has proporcionado tu número de teléfono móvil y has dado tu
          consentimiento para recibir mensajes por WhatsApp de nuestra empresa,
          utilizaremos tu número de teléfono solo para enviar mensajes
          relacionados con nuestros productos y servicios. Siempre puedes optar
          por no recibir mensajes de WhatsApp de nuestra empresa en cualquier
          momento.
        </p>
        <h2 className="contenedor-empresa__quienes-somos__enunciado__titulo politicas">
          Cambios en esta política de privacidad
        </h2>
        <p className="contenedor-empresa__politicas__enunciado__titulo">
          Podemos actualizar esta política de privacidad de vez en cuando.
          Cualquier cambio importante en esta política será comunicado en
          nuestro sitio web.
        </p>
        <h2 className="contenedor-empresa__quienes-somos__enunciado__titulo politicas">
          Datos de contacto de Viviendas la Esperanza
        </h2>
        <p className="contenedor-empresa__politicas__enunciado__titulo">
          Si tienes alguna pregunta o inquietud sobre nuestra política de
          privacidad, no dudes en contactarnos al{" "}
          <a style={{ color: "#000" }} href="tel:08008880168">
            0800-888-0168
          </a>{" "}
          o por WhatsApp al{" "}
          <a
            style={{ color: "#000" }}
            href="https://api.whatsapp.com/send?phone=5491164237022&text=Hola"
            target="_blank"
            rel="noreferrer"
          >
            +54 9 11 6423-7022
          </a>
          .
        </p>
      </Layout>
    </div>
  );
};

export default Politicas;
