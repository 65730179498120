import React from "react";
import "./Buttons.css";

export default function Buttons() {
  const conversion = () => {
    return window.gtag_report_conversion(
      "https://www.viviendaslaesperanza.com/"
    );
  };

  return (
    <div className="Buttons">
      <a
        className="whatsapp-btn trigger visible-xs visible-sm wow bounceIn"
        target="_blank"
        rel="noreferrer"
        /* href="https://api.whatsapp.com/send?phone=5493517169424&amp;text=Hola, quisiera realizar una consulta." */
        href="https://api.whatsapp.com/send?phone=5491164237022&amp;text=Hola, quisiera realizar una consulta."
        id="whatsapp-btn"
        onClick={conversion}
      >
        <i className="fab fa-whatsapp whatsapp-general" aria-hidden="true"></i>
      </a>
    </div>
  );
}
