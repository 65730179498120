export const relatedProducts = (reference, products) => {
  const superficieReference =
    reference?.superficies.metrosCubiertos +
    reference?.superficies.metrosSemicubiertos;

  const productSimilar = products
    .map((item) => {
      const superficieItem =
        item.superficies.metrosCubiertos + item.superficies.metrosSemicubiertos;

      const itemSimilitud = {
        ...item,
        similitud:
          100 -
          (Math.abs(superficieReference - superficieItem) /
            ((superficieReference + superficieItem) / 2)) *
            100,
      };

      if (reference?.tipo === "modelo") {
        if (
          item.id !== reference?.id &&
          item.tipo === reference?.tipo &&
          item.cantidadDePlantas === reference?.cantidadDePlantas
        ) {
          return itemSimilitud;
        }
      } else if (reference?.tipo === "Cabaña") {
        if (item.id !== reference?.id && item.tipo === reference?.tipo) {
          return itemSimilitud;
        }
      }
    })
    .sort((a, b) => {
      if (a.similitud > b.similitud) {
        return -1;
      }
      if (a.similitud < b.similitud) {
        return 1;
      }
      return 0;
    });

  const productDifferentPlantas = products
    .map((item) => {
      const superficieItem =
        item.superficies.metrosCubiertos + item.superficies.metrosSemicubiertos;

      const itemSimilitud = {
        ...item,
        similitud:
          100 -
          (Math.abs(superficieReference - superficieItem) /
            ((superficieReference + superficieItem) / 2)) *
            100,
      };

      if (reference?.tipo === "modelo") {
        if (
          item.id !== reference?.id &&
          item.tipo === reference?.tipo &&
          item.cantidadDePlantas !== reference?.cantidadDePlantas
        ) {
          return itemSimilitud;
        }
      } else if (reference?.tipo === "Cabaña") {
        if (
          item.id !== reference?.id &&
          item.tipo === reference?.tipo &&
          item.linea !== reference?.linea
        ) {
          return itemSimilitud;
        }
      }
    })
    .sort((a, b) => {
      if (a.similitud > b.similitud) {
        return -1;
      }
      if (a.similitud < b.similitud) {
        return 1;
      }
      return 0;
    });

  if (reference?.tipo === "Cabaña") {
    return [
      productSimilar.find((item) => item?.linea === "A"),
      productSimilar.find((item) => item?.linea === "B"),
      productSimilar.find((item) => item?.linea === "C"),
    ];
  }
  return [
    productSimilar.slice(0, 1)[0],
    productSimilar.slice(1, 2)[0],
    productDifferentPlantas.slice(0, 1)[0],
  ];
};
