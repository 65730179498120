import React, { useEffect, useState } from "react";
import "./Showroom.css";
import { Helmet } from "react-helmet";
import { FormCita } from "../../components/Form/FormCita";
import { goTop } from "../../helpers/goTop";

const Showroom = () => {
  useEffect(() => {
    goTop();
  }, []);

  return (
    <>
      <Helmet>
        <title>Showroom | Viviendas La Esperanza</title>
      </Helmet>
      <div className="contenedor-showroom__header">
        <p className="contenedor-showroom__header__text">
          ¡Visitá nuestro Showroom de Casas!
        </p>
      </div>
      <div className="contenedor-showroom__form">
        <FormCita className="contenedor_form_cita" />
        <div className="contenedor_video"></div>
      </div>
    </>
  );
};

export { Showroom };
