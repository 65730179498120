import React from "react";
import "./GraciasCita.css";

const GraciasCita = ({modal}) => {
  return (
    <div className={`containerGraciasCita ${modal && "containerGraciasCita--modal"}`}>
      <h3>¡Felicitaciones por dar el primer paso para tener tu casa propia!</h3>
      <p>Un asesor se va a comunicar a la brevedad.</p>
    </div>
  );
};

export default GraciasCita;
